var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"lg":"3","cols":"8"}},[_c('div',{staticStyle:{"width":"300px"}},[_vm._v("Select report length:")]),_c('v-text-field',{model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}}),_c('v-select',{attrs:{"items":_vm.durationUnits,"single-line":""},model:{value:(_vm.selectedDurationUnit),callback:function ($$v) {_vm.selectedDurationUnit=$$v},expression:"selectedDurationUnit"}})],1),_c('v-col',[_c('v-btn',{attrs:{"elevation":"2"},on:{"click":function($event){return _vm.refresh()}}},[_vm._v("Refresh")])],1)],1),_c('v-row',[_c('v-col',[_c('div',[_c('div',[_vm._v("Total delegated: "+_vm._s(_vm.totalDelegated))]),_c('div',[_vm._v("Total undelegated: "+_vm._s(_vm.totalUndelegated))])])])],1),_c('v-row',{staticClass:"text-center",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Delegation "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.data,"items-per-page":25,"search":_vm.search,"sort-by":_vm.sortColumn,"sort-desc":_vm.sortDesc,"footer-props":{
            'items-per-page-options': [10, 25, 50, 100, -1],
          }},on:{"update:sortBy":function($event){_vm.sortColumn=$event},"update:sort-by":function($event){_vm.sortColumn=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:`item.provider`,fn:function({ item }){return [_c('a',{attrs:{"target":"_blank","href":item.providerUri}},[_vm._v(" "+_vm._s(item.provider ?? item.address)+" ")])]}}],null,true)})],1)],1)],1),_c('v-row',{staticClass:"text-center",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Delegation TXs "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","single-line":"","hide-details":""},model:{value:(_vm.txsearch),callback:function ($$v) {_vm.txsearch=$$v},expression:"txsearch"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","loading":_vm.isLoading,"headers":_vm.TXsHeader,"items":_vm.delegationTXs,"items-per-page":10,"search":_vm.txsearch,"sort-by":_vm.TXSortColumn,"sort-desc":_vm.TXSortDesc,"footer-props":{
            'items-per-page-options': [10, 25, 50, 100, -1],
          }},on:{"update:sortBy":function($event){_vm.TXSortColumn=$event},"update:sort-by":function($event){_vm.TXSortColumn=$event},"update:sortDesc":function($event){_vm.TXSortDesc=$event},"update:sort-desc":function($event){_vm.TXSortDesc=$event}},scopedSlots:_vm._u([{key:`item.provider`,fn:function({ item }){return [_c('a',{attrs:{"target":"_blank","href":item.providerUri}},[_vm._v(" "+_vm._s(item.provider)+" ")])]}},{key:`item.hash`,fn:function({ item }){return [_c('a',{attrs:{"target":"_blank","href":item.explorerUri}},[_vm._v(" Details ")])]}}],null,true)})],1)],1)],1),_c('v-row',{staticClass:"text-center",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Undelegation TXs "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","single-line":"","hide-details":""},model:{value:(_vm.txsearch),callback:function ($$v) {_vm.txsearch=$$v},expression:"txsearch"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","loading":_vm.isLoading,"headers":_vm.TXsHeader,"items":_vm.undelegationTXs,"items-per-page":10,"search":_vm.txsearch,"sort-by":_vm.TXSortColumn,"sort-desc":_vm.TXSortDesc,"footer-props":{
            'items-per-page-options': [10, 25, 50, 100, -1],
          }},on:{"update:sortBy":function($event){_vm.TXSortColumn=$event},"update:sort-by":function($event){_vm.TXSortColumn=$event},"update:sortDesc":function($event){_vm.TXSortDesc=$event},"update:sort-desc":function($event){_vm.TXSortDesc=$event}},scopedSlots:_vm._u([{key:`item.provider`,fn:function({ item }){return [_c('a',{attrs:{"target":"_blank","href":item.providerUri}},[_vm._v(" "+_vm._s(item.provider)+" ")])]}},{key:`item.hash`,fn:function({ item }){return [_c('a',{attrs:{"target":"_blank","href":item.explorerUri}},[_vm._v(" Details ")])]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }