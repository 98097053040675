<template>
  <v-container fill-height fluid>
    <v-row class="text-center" justify="center" align="center">
      <v-col lg="2" cols="5">
        <v-hover v-slot="{ hover }">
          <v-card :to="'/stakes'" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
            <v-responsive
              :aspect-ratio="1 / 1"
              class="d-flex align-center justify-center"
            >
              <v-card-text
                class="font-weight-medium mt-12 text-center"
                :class="menuFont"
                style="margin-top: 0 !important"
              >
                Validation
              </v-card-text>
            </v-responsive>
          </v-card>
        </v-hover>
      </v-col>
      <v-col lg="2" cols="5">
        <v-hover v-slot="{ hover }">
          <v-card :to="'/delegation'" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
            <v-responsive
              :aspect-ratio="1 / 1"
              class="d-flex align-center justify-center"
            >
              <v-card-text
                class="font-weight-medium mt-12 text-center"
                :class="menuFont"
                style="margin-top: 0 !important"
              >
                Delegation
              </v-card-text>
            </v-responsive>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeView",

  methods: {},

  computed: {
    menuFont() {
      if (this.$isMobile()) {
        return "text-h6"
      } else {
        return "text-h4"
      }
    }
  },
};
</script>
