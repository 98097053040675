<template>
  <v-container>
    <v-row align="center">
      <v-col lg="3" cols="8" class="d-flex align-center">
        <div style="width: 300px">Select report length:</div>
        <v-text-field v-model="duration"></v-text-field>
        <v-select
          v-model="selectedDurationUnit"
          :items="durationUnits"
          single-line
        ></v-select>
      </v-col>
      <v-col>
        <v-btn elevation="2" @click="refresh()">Refresh</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
          <div>Total delegated: {{ totalDelegated }}</div>
          <div>Total undelegated: {{ totalUndelegated }}</div>
        </div>
      </v-col>
    </v-row>
    <v-row class="text-center" justify="center">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Delegation
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Filter"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            dense
            :loading="isLoading"
            :headers="headers"
            :items="data"
            :items-per-page="25"
            class="elevation-1"
            :search="search"
            :sort-by.sync="sortColumn"
            :sort-desc.sync="sortDesc"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100, -1],
            }"
          >
            <template v-slot:[`item.provider`]="{ item }">
              <a target="_blank" :href="item.providerUri">
                {{ item.provider ?? item.address }}
              </a>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="text-center" justify="center">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Delegation TXs
            <v-spacer></v-spacer>
            <v-text-field
              v-model="txsearch"
              append-icon="mdi-magnify"
              label="Filter"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            dense
            :loading="isLoading"
            :headers="TXsHeader"
            :items="delegationTXs"
            :items-per-page="10"
            class="elevation-1"
            :search="txsearch"
            :sort-by.sync="TXSortColumn"
            :sort-desc.sync="TXSortDesc"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100, -1],
            }"
          >
            <template v-slot:[`item.provider`]="{ item }">
              <a target="_blank" :href="item.providerUri">
                {{ item.provider }}
              </a>
            </template>
            <template v-slot:[`item.hash`]="{ item }">
              <a target="_blank" :href="item.explorerUri">
                Details
              </a>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="text-center" justify="center">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Undelegation TXs
            <v-spacer></v-spacer>
            <v-text-field
              v-model="txsearch"
              append-icon="mdi-magnify"
              label="Filter"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            dense
            :loading="isLoading"
            :headers="TXsHeader"
            :items="undelegationTXs"
            :items-per-page="10"
            class="elevation-1"
            :search="txsearch"
            :sort-by.sync="TXSortColumn"
            :sort-desc.sync="TXSortDesc"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100, -1],
            }"
          >
            <template v-slot:[`item.provider`]="{ item }">
              <a target="_blank" :href="item.providerUri">
                {{ item.provider }}
              </a>
            </template>
            <template v-slot:[`item.hash`]="{ item }">
              <a target="_blank" :href="item.explorerUri">
                Details
              </a>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

export default {
  name: "DelegationList",

  data: () => ({
    sortColumn: "egldDelegated",
    sortDesc: true,
    TXSortColumn: "value",
    TXSortDesc: true,
    search: "",
    txsearch: "",
    headers: [],
    data: [],
    isLoading: false,
    duration: "24",
    selectedDurationUnit: "hours",
    durationUnits: ["minutes", "hours", "days"],
    totalDelegated: 0,
    totalUndelegated: 0,
    providerNames: {},
    TXsHeader: [],
    delegationTXs: [],
    undelegationTXs: [],
  }),

  async mounted() {
    await this.getProviders();

    this.TXsHeader = [
      {
        text: "Provider",
        align: "start",
        value: "provider",
      },
      {
        text: "Timestamp",
        value: "timestamp",
      },
      { text: "Value", value: "value" },
      { text: "Explorer", value: "hash" },
    ];

    this.headers = [
      {
        text: "Provider",
        align: "start",
        value: "provider",
      },
      { text: "Delegated", value: "egldDelegated" },
      { text: "Undelegated", value: "egldUndelegated" },
    ];
  },

  methods: {
    async refresh() {
      this.data = [];
      this.delegationTXs = [];
      this.undelegationTXs = [];
      this.totalDelegated = 0;
      this.totalUndelegated = 0;
      this.isLoading = true;

      let [providersFromDeleg, delegationTxs, totalDelegated] =
        await this.getDelegData("ZGVsZWdhdGU.*", "delegate");
      let [providersFromUndeleg, undelegationTxs, totalUndelegated] =
        await this.getDelegData("dw5ezwxlz2f0.*", "unDelegate");
      this.totalDelegated = totalDelegated;
      this.totalUndelegated = totalUndelegated;

      var all = [];

      for (const [addr, prov] of Object.entries(providersFromDeleg)) {
        let complete = {
          provider: prov.provider,
          address: prov.address,
          providerUri: prov.providerUri,
          egldDelegated: prov.value,
          egldUndelegated: providersFromUndeleg[addr]?.value ?? 0,
        };
        all.push(complete);
      }

      for (const [addr, prov] of Object.entries(providersFromUndeleg)) {
        if (!(addr in providersFromDeleg)) {
          let complete = {
            provider: prov.provider,
            address: prov.address,
            providerUri: prov.providerUri,
            egldDelegated: providersFromDeleg[addr]?.value ?? 0,
            egldUndelegated: prov.value,
          };
          all.push(complete);
        }
      }

      this.isLoading = false;
      this.data = all;

      this.delegationTXs = delegationTxs;
      this.undelegationTXs = undelegationTxs;
    },

    async getProviders() {
      let resp = await Vue.axios.get(
        "https://elrond.api.beeftech.cloud/stakingProviders"
      );
      let providersData = resp.data;
      var providers = {};
      for (const key in providersData) {
        const provider = providersData[key];
        providers[provider.address] = provider?.metadata?.name;
      }
      this.providerNames = providers;
    },

    async getDelegData(transactionDataFilter, decodedDataFilter) {
      const uri = "https://elrond.api.beeftech.cloud/transactions";
      // const uri = "http://0.0.0.0:8080/transactions";

      let number = parseInt(this.duration);
      var seconds = 0;

      switch (this.selectedDurationUnit) {
        case "minutes":
          seconds = number * 60;
          break;
        case "hours":
          seconds = number * 60 * 60;
          break;
        case "days":
          seconds = number * 60 * 60 * 24;
          break;
      }

      let fromSeconds = Math.floor(new Date().getTime() / 1000 - seconds);

      var resp = null;
      var data = null;

      try {
        resp = await Vue.axios.post(uri, {
          address: "",
          pageSize: 10000,
          startTimestamp: fromSeconds,
          newer: true,
          includeScResults: false,
          processTransactions: true,
          dataFilter: transactionDataFilter,
          decodedDataFilter: decodedDataFilter,
        });
        data = resp.data;
      } catch (error) {
        console.error(error);
        return [{}, [], 0];
      }

      var allTransactions = [];
      allTransactions = allTransactions.concat(data.transactions);
      var hasMore = data.hasMore;
      var startTs = data.lastTimestamp;

      while (hasMore) {
        try {
          let nextResp = await Vue.axios.post(uri, {
            address: "",
            pageSize: 10000,
            startTimestamp: startTs,
            newer: true,
            includeScResults: false,
            processTransactions: true,
            dataFilter: transactionDataFilter,
            decodedDataFilter: decodedDataFilter,
          });
          allTransactions = allTransactions.concat(nextResp.data.transactions);
          hasMore = nextResp.data.hasMore;
        } catch (error) {
          console.error(error);
          return [{}, [], 0];
        }
      }

      let mapped = allTransactions
        .map((tx) => {
          let date = new Date(tx.timestamp * 1000);
          return {
            hash: tx.hash,
            value: tx.outValues[0].denominated,
            date: date.toLocaleDateString(),
            time: date.toLocaleTimeString(),
            timestamp: tx.timestamp,
            receiver: tx.receiver,
          };
        })
        .sort((t1, t2) => t2.value - t1.value);

      let transactions = [...new Set(mapped)];

      var grouped = this.groupObjects(transactions, "receiver");

      var providersTotals = [];
      for (let key in grouped) {
        let sptrans = grouped[key];
        let total = sptrans.reduce((sum, current) => sum + current.value, 0);
        let totalFormatted = (Math.round(total * 100) / 100).toFixed(2);
        providersTotals.push({ total: totalFormatted, addr: key });
      }

      var providersDict = {};

      providersTotals
        .sort((t1, t2) => t2.total - t1.total)
        .forEach((sp) => {
          let delegated = {
            provider: this.providerNames[sp.addr] ?? "Unnamed",
            address: sp.addr,
            providerUri: `https://explorer.elrond.com/providers/${sp.addr}`,
            value: sp.total,
          };
          providersDict[sp.addr] = delegated;
        });

      let total = transactions.reduce((sum, current) => sum + current.value, 0);
      let totalValue = (Math.round(total * 100) / 100).toFixed(2);

      var formattedTransactions = [];

      transactions.forEach((transaction) => {
        let valueFormatted = (
          Math.round(transaction.value * 100) / 100
        ).toFixed(2);
        formattedTransactions.push({
          timestamp: `${transaction.date} ${transaction.time}`,
          value: valueFormatted,
          provider: this.providerNames[transaction.receiver] ?? "Unnamed",
          providerUri: `https://explorer.elrond.com/providers/${transaction.receiver}`,
          hash: transaction.hash,
          explorerUri: `https://explorer.elrond.com/transactions/${transaction.hash}`,
        });
      });

      return [providersDict, formattedTransactions, totalValue];
    },

    groupObjects(list, key) {
      return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
  },
};
</script>
