import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../components/Home'
import StakesList from '../components/Stakes'
import DelegationList from '../components/Delegations'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/stakes',
    name: 'stakes',
    component: StakesList
  },
  {
    path: '/delegation',
    name: 'delegation',
    component: DelegationList
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
