<template>
  <v-container>
    <v-row class="text-center" justify="center">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Stakes
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Filter"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table dense
            :loading="isLoading"
            :headers="headers"
            :items="stakes"
            :items-per-page="25"
            class="elevation-1"
            :search="search"
            :sort-by.sync="sortColumn"
            :sort-desc.sync="sortDesc"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100, -1]
            }"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'StakesList',

    data: () => ({
      sortColumn: 'staked.denominated',
      sortDesc: true,
      search: '',
      headers: [],
      stakes: [],
      isLoading: true,
    }),

    mounted() {
      this.getStakes();

      if (this.$isMobile()) {
        this.headers = [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Stake', value: 'staked.denominated' },
          { text: 'TopUp', value: 'topUp.denominated' },
          { text: 'TopUp per Node', value: 'topUpPerNode.denominated' },
          { text: 'Nodes', value: 'numNodes' },
          { text: 'First Node position', value: 'nodeStartIdx' },
        ]
      } else {
        this.headers = [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Address',
            align: 'start',
            sortable: false,
            value: 'address',
          },
          { text: 'Stake', value: 'staked.denominated' },
          { text: 'TopUp', value: 'topUp.denominated' },
          { text: 'TopUp per Node', value: 'topUpPerNode.denominated' },
          { text: 'Nodes', value: 'numNodes' },
          { text: 'First Node position', value: 'nodeStartIdx' },
        ]
      }
    },

    methods: {
      getStakes() {
        Vue.axios.get('https://elrond.api.beeftech.cloud/stake?includeBlsKeys=false&sort=TopUpPerNode&nodeCount=true').then((response) => {
          this.stakes = response.data;
          this.isLoading = false;
        }).catch(err => {
          console.log(err.response);
          this.isLoading = false;
        });
      }
    },
  }
</script>
