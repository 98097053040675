<template>
  <v-app>
    <v-app-bar app>
      <router-link to="/" style="text-decoration: none; color: inherit">
        <v-toolbar-title class="headline text-uppercase">
          <span>Partner Staking</span>
          <span class="font-weight-light">Statistics</span>
        </v-toolbar-title>
      </router-link>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down d-flex align-center" v-if="!$isMobile()">
        <span>
          <router-link
            style="text-decoration: none; color: inherit"
            to="/stakes"
            >Stakes</router-link
          >
        </span>
        <span class="ml-4">
          <router-link
            style="text-decoration: none; color: inherit"
            to="/delegation"
            >Delegation</router-link
          >
        </span>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      //
    };
  },
};
</script>
